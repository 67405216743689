<template>
  <div class="space-y-4">
    <asom-client-table
      :filterable="false"
      :pagination="false"
      v-if="!isLoading"
      :columns="['planDate', 'dws']"
      :data="rosters"
    >
      <template v-slot:header_planDate>Plan Date</template>
      <template v-slot:header_dwsText>dws</template>
      <template v-slot:planDate="scopedSlots">
        {{ displayDateTime(scopedSlots.data, "DD/MM/YYYY") }}
      </template>
    </asom-client-table>
    <div class="text-center" v-else>
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
  </div>
</template>
<script>
import get from "lodash.get";
import { getRosterList } from "@/services/manpower.service";
import {
  parseDateTime,
  displayDateTime,
} from "../../../helpers/dateTimeHelpers";

export default {
  props: {
    officerId: {
      type: String,
      required: true,
    },
    lineId: {
      type: String,
    },
    stationId: {
      type: String,
    },
    firstDate: {
      type: undefined,
    },
    secondDate: {
      type: undefined,
    },
    rosters: {
      type: Array,
    },
    isSecondOfficer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  emits: ["update:rosters"],
  mounted() {
    this.loadRosterData();
  },
  watch: {
    officerId: function(newValue, oldValue) {
      if (!!newValue && newValue !== oldValue) {
        this.loadRosterData();
      }
    },
    firstDate: function(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.loadRosterData(false);
        });
      }
    },
    secondDate: function(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.loadRosterData(true);
        });
      } else {
        this.$nextTick(() => {
          this.loadRosterData(false);
        });
      }
    },
  },
  methods: {
    displayDateTime,
    async loadRosterData(useSecondDate = false) {
      if (!useSecondDate) this.$emit("update:rosters", []);

      const date = !useSecondDate ? this.firstDate : this.secondDate;

      if (this.officerId && this.lineId && date) {
        this.isLoading = true;
        const resp = await getRosterList({
          officers: [this.officerId],
          stationId: this.stationId,
          lineId: this.lineId,
          dateFrom: parseDateTime(date),
          dateTo: parseDateTime(date),
        });
        if (resp.success) {
          let rosterList = [];
          if (!useSecondDate) rosterList = get(resp, "payload.list", []);
          else {
            rosterList = [...this.rosters];
            if (rosterList.length == 2) {
              rosterList = rosterList.slice(0, 1);
            }

            rosterList = [...rosterList, ...get(resp, "payload.list", [])];

            rosterList = rosterList.filter((obj, index, arr) => {
              return (
                arr.map((mapObj) => mapObj.rosterId).indexOf(obj.rosterId) ===
                index
              );
            });
          }
          this.$emit("update:rosters", rosterList);
          this.error = null;
          this.isLoading = false;
        } else {
          this.error = resp.payload;
          this.isLoading = false;
          this.$scrollTop();
          return;
        }
      }
    },
  },
};
</script>

<style></style>
