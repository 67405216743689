import { required } from "@vuelidate/validators";

export default () => {
  return {
    formData: {
      officer1: {
        officerId: { required },
      },
      officer2: {
        officerId: { required },
      },
      plannedDate1: { required },
      remarks: { required },
    },
  };
};
