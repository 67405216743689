const ROLES = {
  SYSTEM_ADMIN: {
    key: "SystemAdmin",
    name: "System Admin",
  },
  MANAGER_STATION_OPERATION: {
    key: "ManagerStationOperation",
    name: "Manager Station Operation",
  },
  SERVICE_OPERATION_MANAGER: {
    key: "ServiceOperationManager",
    name: "Service Operations Manager",
  },
  STATION_MANAGER: {
    key: "StationManager",
    name: "Station Manager",
  },
  SENIOR_STATION_MANAGER: {
    key: "SeniorStationManager",
    name: "Senior Station Manager",
  },
  ASSISTANT_STATION_MANAGER: {
    key: "AssistantStationManager",
    name: "Assistant Station Manager",
  },
  SENIOR_ASSISTANT_STATION_MANAGER: {
    key: "SeniorAssistantStationManager",
    name: "Senior Assistant Station Manager",
  },
  STATION_OPERATION_MANAGEMENT: {
    key: "RoleStationOperationsManagement",
    name: "Stn Ops Management"
  },
  OPERATION_CONTROL_CENTER: {
    key: "OperationControlCentre",
    name: "Operation Control Centre",
  },
  STATION_MANAGER_CONTROL_ROOM: {
    key: "StationManagerControlRoom",
    name: "Station Manager (Control Room)",
  },
  FSO_MANAGER: {
    key: "FSOManager",
    name: "FSO Manager",
  },
  FSO_ADMIN_OFFICER: {
    key: "FSOAdministrativeOfficer",
    name: "FSO Administrative Officer",
  },
  CST_LEADER: {
    key: "CSTLeader",
    name: "CST Leader",
  },
  CST_MEMBER: {
    key: "CSTMember",
    name: "CST Member",
  },
  OPS_ADMIN: {
    key: "OpsAdmin",
    name: "Ops Admin",
  },
}

export default ROLES;