<template>
  <div class="mutual_exchange">
    <asom-card>
      <form class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
        <asom-alert
          class="md:col-span-2"
          v-if="error"
          variant="error"
          :error-message="error"
        >
        </asom-alert>
        <asom-form-field
          label="Select a station"
          required
          :state="inputStates('formData.officer1.stationId')"
          error="Select a station"
        >
          <asom-input-select
            :objectModelValue="false"
            :state="inputStates('formData.officer1.stationId')"
            :disabled="hasQuery"
            v-model="formData.officer1.stationId"
            :options="stationOptions"
          />
        </asom-form-field>
        <asom-form-field
          label="Select a station"
          required
          :state="inputStates('formData.officer2.stationId')"
          error="Select a station"
        >
          <asom-input-select
            :objectModelValue="false"
            :state="inputStates('formData.officer2.stationId')"
            v-model="formData.officer2.stationId"
            :options="stationOptions"
          />
        </asom-form-field>
        <asom-form-field
          label="Officer 1"
          required
          :state="inputStates('formData.officer1.officerId')"
          error="Select an officer"
        >
          <asom-input-select
            :objectModelValue="false"
            :state="inputStates('formData.officer1.officerId')"
            :disabled="hasQuery"
            v-model="formData.officer1.officerId"
            :options="officer1Options"
          />
        </asom-form-field>
        <asom-form-field
          label="Officer 2"
          required
          :state="inputStates('formData.officer2.officerId')"
          error="Select an officer"
        >
          <asom-input-select
            :objectModelValue="false"
            :state="inputStates('formData.officer2.officerId')"
            v-model="formData.officer2.officerId"
            :options="filteredOfficer2Options"
          />
        </asom-form-field>
        <asom-form-field
          v-if="formData.officer1.officerId || formData.officer2.officerId"
          label="Date of Exchange"
          required
          :state="inputStates('formData.plannedDate1')"
          error="Please provide date"
        >
          <asom-input-date
            :min-date="new Date()"
            v-model="formData.plannedDate1"
            :disabled="hasQuery"
          />
        </asom-form-field>
        <div v-else></div>
        <asom-form-field
          v-if="formData.officer2.officerId && formData.need2ndDate"
          label="Second Date of Exchange"
          description="A second date is required for this exchange"
          required
          :state="inputStates('formData.plannedDate2')"
          error="Please provide date"
        >
          <asom-input-date
            :min-date="new Date()"
            v-model="formData.plannedDate2"
          />
        </asom-form-field>
        <div v-else></div>
        <shift-selection
          v-if="formData.officer1.officerId"
          v-model:rosters="formData.officer1.rosters"
          :first-date="formData.plannedDate1"
          :second-date="formData.plannedDate2"
          :officerId="formData.officer1.officerId"
          :stationId="formData.officer1.stationId"
          :lineId="lineId"
        />
        <div v-else></div>
        <shift-selection
          v-if="formData.officer2.officerId"
          v-model:rosters="formData.officer2.rosters"
          :first-date="formData.plannedDate1"
          :second-date="formData.plannedDate2"
          :officerId="formData.officer2.officerId"
          :stationId="formData.officer2.stationId"
          :lineId="lineId"
          is-second-officer
        />
        <div v-else></div>
        <asom-form-field
          required
          label="Remarks"
          :state="inputStates('formData.remarks')"
        >
          <asom-input-textarea
            type="text"
            v-model="formData.remarks"
            :state="inputStates('formData.remarks')"
            :maxlength="1000"
          />
        </asom-form-field>
      </form>
      <template #footer>
        <asom-button
          text="Cancel"
          variant="secondary"
          @click="$router.push({ name: 'Supervisor Roster' })"
        />
        <asom-button
          variant="primary"
          text="Submit Exchange Request"
          @click="onSubmit(false)"
          :disabled="!isFormFilled"
          :loading="isSubmitting"
        />
      </template>
    </asom-card>
    <asom-modal
      title-icon="error"
      title="Confirmation"
      v-model="showMaxOTHourModal"
      :dismissible="false"
    >
      <p>You are exceeding the max workable OT hours by MOM rule</p>
      <p>Do you want to continue?</p>
      <br />
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="onSubmit(true)"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          text="Confirm"
        />
        <asom-button
          @click="showMaxOTHourModal = false"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
  </div>
</template>

<script>
import moment from 'moment';
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";

import { listUsers } from "@/services/user.service";
import {
  createMutualExchange,
  validateMutualExchange,
} from "@/services/manpower.service";
import ShiftSelection from "./ShiftSelection.vue";
import validations from "./validations";
import { parseDateTime } from "../../../helpers/dateTimeHelpers";
import ROLES from '../../../constants/APIEnums/roles';

export default {
  components: {
    ShiftSelection,
  },
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    let stationId = null;
    let plannedDate1 = null;
    if (this.$store.state.auth.station) {
      stationId = this.$store.state.auth.station.stationId;
    }
    const hasQuery = this.$route.query && !!this.$route.query.officer;
    if (hasQuery) {
      stationId = this.$route.query.stationId
        ? this.$route.query.stationId
        : stationId;
      plannedDate1 = moment(this.$route.query.date, "DD/MM/YYYY");
    }

    return {
      formData: {
        officer1: {
          stationId: stationId,
          officerId: null,
          rosters: [],
        },
        officer2: {
          stationId: stationId,
          officerId: null,
          rosters: [],
        },
        plannedDate1:
          plannedDate1 && plannedDate1.isValid()
            ? plannedDate1.toDate()
            : new Date(),
        plannedDate2: null,
        need2ndDate: false,
        role: null,
        remarks: null,
      },
      officer1Options: [],
      officer2Options: [],
      isLoading: false,
      isSubmitting: false,
      error: null,
      serverError: null,
      showMaxOTHourModal: false,
    };
  },
  validations: validations,
  mounted() {
    this.v$ && this.v$.$reset();
    this.prepareData();
  },
  watch: {
    "formData.officer1.stationId": function() {
      this.loadOfficerOfStation({ officer1: true });
      this.onStationChanged({ officer1: true });
    },
    "formData.officer2.stationId": function() {
      this.loadOfficerOfStation({ officer1: false });
      this.onStationChanged({ officer1: false });
    },
    "formData.officer1.officerId": function(newValue, oldValue) {
      this.formData.need2ndDate = false;
      if (newValue && newValue != oldValue) {
        this.validateExchange();
        this.updateRoleAndCheckOfficer2Selection();
      }
    },
    "formData.officer2.officerId": function(newValue, oldValue) {
      this.formData.plannedDate2 = null;
      this.formData.need2ndDate = false;
      if (newValue && newValue != oldValue) {
        this.validateExchange();
      }
    },
    "formData.plannedDate1": function(newValue, oldValue) {
      this.formData.plannedDate2 = null;
      this.formData.need2ndDate = false;
      if (newValue && newValue != oldValue) {
        this.validateExchange();
      }
    },
    error: function(newValue) {
      if (newValue) {
        this.$scrollTop();
      }
    },
    officer1Options(newValue, oldValue) {
      if (!newValue && oldValue.length === 0)
        this.updateRoleAndCheckOfficer2Selection();
    },
    officer2Options(newValue, oldValue) {
      if (!newValue && oldValue.length === 0)
        this.updateRoleAndCheckOfficer2Selection();
    },
  },
  computed: {
    lineId() {
      return this.$store.getters["auth/userLineId"];
    },
    stationOptions() {
      var userLineId = this.$store.getters["auth/userLineId"];
      var stations = this.$store.getters["smrtResource/stationOptionsByLineId"](
        userLineId
      );
      return stations;
    },
    isOfficersAndDatesSelected() {
      return (
        this.formData.officer1.stationId &&
        this.formData.officer2.stationId &&
        this.formData.officer1.officerId &&
        this.formData.officer2.officerId &&
        this.formData.plannedDate1
      );
    },
    isFormFilled() {
      return (
        !this.v$.$invalid &&
        this.isOfficersAndDatesSelected &&
        this.formData.officer1.rosters &&
        this.formData.officer2.rosters &&
        (!this.formData.need2ndDate ||
          (this.formData.plannedDate1 &&
            this.formData.plannedDate2 &&
            this.formData.plannedDate1.toString() !==
              this.formData.plannedDate2.toString()))
      );
    },
    hasQuery() {
      return this.$route.query && !!this.$route.query.officer;
    },
    filteredOfficer2Options() {
      return this.formData.role
        ? this.officer2Options.filter(({ value, roles = [] }) =>
            value != this.formData.officer1.officerId && this.hasMatchRole(roles)
          )
        : this.officer2Options.filter(({ value }) =>
            value != this.formData.officer1.officerId
          );
    },
  },
  methods: {
    hasMatchRole(roles = []) {
      let matchedRoles = [
        this.formData.role
      ];
      switch (this.formData.role) {
        case ROLES.STATION_MANAGER.name:
        case ROLES.SENIOR_STATION_MANAGER.name:
          matchedRoles = [
            ROLES.STATION_MANAGER.name,
            ROLES.SENIOR_STATION_MANAGER.name,
          ]
          break;
        case ROLES.ASSISTANT_STATION_MANAGER.name:
        case ROLES.SENIOR_ASSISTANT_STATION_MANAGER.name:
          matchedRoles = [
            ROLES.ASSISTANT_STATION_MANAGER.name,
            ROLES.SENIOR_ASSISTANT_STATION_MANAGER.name,
          ]
          break;
        default:
          break;
      }
      return matchedRoles.includes(get(roles, '0', ''));
    },
    updateRoleAndCheckOfficer2Selection() {
      const isResourceAvail =
        !isEmpty(this.formData.officer1.officerId) &&
        !isEmpty(this.officer1Options) &&
        !isEmpty(this.officer2Options);
      if (isResourceAvail) {
        const officer1 = this.officer1Options.find(
          (o) => o.value == this.formData.officer1.officerId
        );
        if (officer1 != null) {
          this.formData.role = get(officer1, "roles.0", null);
          this.$nextTick(function() {
            const officer2 = this.officer2Options.find(
              (o) => o.value == this.formData.officer2.officerId
            );
            if (officer2 != null) {
              if (!this.hasMatchRole(get(officer2, "roles", []))) {
                this.formData.officer2.officerId = null;
              }
            }
          });
        } else {
          console.error("Cannot find officer 1's role");
        }
      } else {
        this.formData.role = null;
      }
    },
    async validateExchange() {
      if (this.isOfficersAndDatesSelected) {
        const result = await validateMutualExchange({
          officer1: {
            officerId: this.formData.officer1.officerId,
            plannedDate: parseDateTime(this.formData.plannedDate1, false),
          },
          officer2: {
            officerId: this.formData.officer2.officerId,
            plannedDate: parseDateTime(this.formData.plannedDate1, false),
          },
        });
        if (result.success) {
          this.formData.need2ndDate = get(result, "payload.need2ndDate", false);
        } else {
          this.error = result.payload;
          this.$scrollTop();
        }
      }
    },
    onStationChanged({ officer1 = true }) {
      if (officer1) {
        this.formData.officer1.officerId = null;
        this.formData.plannedDate1 = null;
        this.formData.plannedDate2 = null;
      } else {
        this.formData.officer2.officerId = null;
        this.formData.plannedDate2 = null;
      }
      this.v$ && this.v$.$reset();
    },
    async onSubmit(forced = false) {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        const date1 = this.formData.plannedDate1;
        const date2 = this.formData.need2ndDate
          ? this.formData.plannedDate2
          : this.formData.plannedDate1;
        const result = await createMutualExchange({
          officer1: {
            officerId: this.formData.officer1.officerId,
            plannedDate: parseDateTime(date1, false),
          },
          officer2: {
            officerId: this.formData.officer2.officerId,
            plannedDate: parseDateTime(date2, false),
          },
          remarks: this.formData.remarks,
          forced: forced,
        });
        this.isSubmitting = false;
        this.error = "";
        if (result.success) this.$router.push({ name: "Supervisor Roster" });
        else {
          this.error = result.payload;
          const forcible = get(result, "payload.forcible", false);
          if (forcible) {
            this.showMaxOTHourModal = true;
          }
          this.$scrollTop();
        }
      } else {
        this.error = "Please complete all required fields";
      }
    },
    async loadOfficerOfStation({ officer1 = true }) {
      let stationId = officer1
        ? this.formData.officer1.stationId
        : this.formData.officer2.stationId;
      if (!stationId) return;
      const stationOption = this.stationOptions.find(
        (s) => s.value === stationId
      );
      if (!stationOption) return;
      const query = stationOption.name;
      this.isLoading = true;
      const result = await listUsers({ search: query.trim() });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        const officerOptions = get(result, "payload.list").map(
          ({ id, name, roles }) => ({
            label: name,
            value: id,
            roles: roles,
          })
        );
        if (officer1) {
          this.officer1Options = officerOptions;
        } else {
          this.officer2Options = officerOptions;
        }
        this.isLoading = false;
        this.error = null;
      } else {
        if (officer1) {
          this.officer1Options = [];
        } else {
          this.officer2Options = [];
        }
        this.isLoading = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    async prepareData() {
      await this.loadOfficerOfStation({ officer1: true });
      await this.loadOfficerOfStation({ officer1: false });

      if (this.hasQuery) {
        this.formData.officer1.officerId = this.$route.query.officer;
      }
    },
  },
};
</script>

<style scope>
.mutual_exchange .AsomCard__Body {
  @apply sm:rounded-tr-md sm:rounded-tl-md;
}
</style>
